@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}
